import dynamic from 'next/dynamic';

import { SanityVideoEmbed } from '@/lib/sanity/queries/videoEmbed';
import { cn } from '@/lib/utils';
import { Media } from '@/components/Media';

import { ConsentBoundary } from './ConsentBoundary';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

export function VideoEmbed(
  props: SanityVideoEmbed & {
    className?: string;
  },
) {
  return (
    <div
      className={cn(
        'relative -mx-7 max-w-750 pb-4 pr-4 m:mx-auto m:pb-15 m:pr-15',
        props.className,
      )}
    >
      <div className="absolute bottom-0 left-4 right-0 top-14 rounded-15 border-1 border-charcoal bg-charcoal m:left-15 m:top-15 m:rounded-25"></div>
      <div className="relative aspect-[16/9] overflow-clip rounded-15 border-1 border-charcoal bg-grayscale-01 m:rounded-25">
        <ConsentBoundary urlsRequiredForRender={[props.videoUrl]}>
          <ReactPlayer
            width="100%"
            height="100%"
            url={props.videoUrl}
            className="absolute inset-0 size-full"
            controls={true}
            playsinline={true}
            playing={true}
            light={
              <div className="absolute inset-0 size-full">
                <Media
                  media={props.thumbnailImage}
                  className="size-full"
                  placeholder={true}
                  sizes={['100vw', { m: '733px' }]}
                />
                <div className="absolute inset-x-0 top-0 h-1/2 w-full bg-gradient-to-b from-charcoal/65 to-charcoal/0"></div>
                <div className="absolute inset-x-15 top-15 flex items-center gap-x-10">
                  <div className="rounded-full bg-electric px-10 text-14 font-normal leading-150 hidden m:block">
                    {props.badge}
                  </div>
                  <h3 className="text-22 font-normal leading-120 text-cloud !mt-0">
                    {props.title}
                  </h3>
                </div>
              </div>
            }
            playIcon={
              <div className="absolute left-1/2 top-1/2 grid size-78 -translate-x-1/2 -translate-y-1/2 place-items-center rounded-full border-1 border-charcoal bg-electric transition hover:scale-110 hover:border-cloud hover:bg-charcoal hover:text-cloud">
                <svg
                  className="w-32 translate-x-5"
                  viewBox="0 0 33 39"
                  fill="none"
                >
                  <path
                    d="M1.25 1.74648L32 19.5L1.25 37.2535L1.25 1.74648Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            }
          />
        </ConsentBoundary>
      </div>
    </div>
  );
}
