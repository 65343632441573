import { forwardRef, Ref, useEffect, useState } from 'react';
import { highlight, languages } from 'prismjs';

import { cn } from '@/lib/utils';

import 'prismjs/themes/prism.css';

type Props = {
  className?: string;
  code?: string;
  language?: 'js' | string;
};

export const CodeBlock = forwardRef(function CodeBlock(
  { className, code = '', language = 'js' }: Props,
  ref: Ref<HTMLPreElement>,
) {
  const selectedLanguage = languages?.[language] ?? languages.js;
  const [html, setHtml] = useState(highlight(code, selectedLanguage, language));

  useEffect(() => {
    setHtml(highlight(code, selectedLanguage, language));
  }, [language, code, selectedLanguage]);

  return (
    <pre
      ref={ref}
      className={cn(
        `language-${language}`,
        'min-h-[40px] overflow-auto !rounded-15 !bg-grayscale-01 !p-32 !font-azeret m:rounded-25 [&_*]:!bg-transparent',
        className,
      )}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
});
