import {
  PortableText,
  PortableTextBlock,
  PortableTextTypeComponentProps,
} from 'next-sanity';
import slugify from 'slugify';

import { SanityMedia } from '@/lib/sanity/queries/media';
import { SanityVideoEmbed } from '@/lib/sanity/queries/videoEmbed';
import { SanityVideoLoop } from '@/lib/sanity/queries/videoLoop';
import { cn } from '@/lib/utils';
import { Link } from '@/components/Link';

import { BasicText } from './BasicText';
import { Button } from './Button';
import { CodeBlock } from './CodeBlock';
import { ContentTable } from './ContentTable';
import { Media } from './Media';
import { VideoEmbed } from './VideoEmbed';

//TODO: we should type this better, lots of anys on PortableTextBlock items
type Props = {
  value: PortableTextBlock[];
  className?: string;
  normalTextSizeClassName?: string;
  excludedStrings?: string[];
};

function generateId(children: any, excludedStrings?: string[]): string {
  const extractText = (children: any): string => {
    if (Array.isArray(children)) {
      return children.map(extractText).join('');
    } else if (
      typeof children === 'object' &&
      children.props &&
      children.props.children
    ) {
      return extractText(children.props.children);
    } else if (typeof children === 'string') {
      return children;
    }
    return '';
  };

  const text = extractText(children);
  const cleanedText =
    excludedStrings
      ?.reduce((acc, str) => acc.replace(new RegExp(str, 'g'), ''), text)
      .trim() || text.trim();

  return slugify(cleanedText, { strict: true, lower: true });
} /***/

/**
 * The `RichText` component is responsible for rendering rich text content as defined in sanity.

 *
 * Props:
 * - `value` (PortableTextBlock[]): The rich text content to be rendered (will be sourced from sanity).
 * - `className` (string, optional): Additional class names to apply to the root element.
 * - `normalTextSizeClassName` (string, optional): Class name for sizing the default body text. Defaults to "text-16 m:text-20". There are times when we want rich text, but do not want a user to be able to choose text rendering display properties. This prop allows us to control the text size  
 * - `excludedStrings` (string[], optional): An array of strings to be excluded from the generated IDs for headings.
 *
 * @param {Object} props - The props for the component.
 * @param {PortableTextBlock[]} props.value - The rich text content to be rendered.
 * @param {string} [props.className] - Additional class names to apply to the root element.
 * @param {string} [props.normalTextSizeClassName="text-16 m:text-20"] - Class name for sizing the default body text.
 * @param {string[]} [props.excludedStrings] - An array of strings to be excluded from the generated IDs for headings.
 * @returns {JSX.Element} The rendered rich text content as HTML output.
 */

export function RichText({
  value,
  className,
  excludedStrings,
  normalTextSizeClassName = 'text-16 m:text-20',
}: Props) {
  return (
    <div className={cn('rich-text', className)}>
      <PortableText
        value={value}
        components={{
          block: {
            h2: ({ children }) => {
              const id = generateId(children, excludedStrings);
              return (
                <h2
                  id={id}
                  className="text-pretty text-26 font-[440] leading-116 -tracking-1 m:text-34"
                >
                  {children}
                </h2>
              );
            },
            h3: ({ children }) => {
              const id = generateId(children, excludedStrings);
              return (
                <h3
                  id={id}
                  className="text-pretty text-22 font-[440] leading-116 -tracking-1 m:text-28"
                >
                  {children}
                </h3>
              );
            },
            h4: ({ children }) => {
              const id = generateId(children, excludedStrings);
              return (
                <h4
                  id={id}
                  className="text-pretty text-18 font-[440] leading-116 -tracking-1 m:text-22"
                >
                  {children}
                </h4>
              );
            },
            normal: ({ children }) => (
              <p
                className={cn(
                  'text-pretty font-light leading-160 text-grayscale-06',
                  normalTextSizeClassName,
                )}
              >
                {children}
              </p>
            ),
            blockquote: ({ children }) => (
              <blockquote className="my-50 text-pretty border-l-1 border-grayscale-02 py-10 pl-40 text-22 font-[440] italic leading-150">
                {children}
              </blockquote>
            ),
            tocHeading: () => null,
          },
          list: {
            bullet: ({ children }) => (
              <ul className="ml-[1.6em] list-disc">{children}</ul>
            ),
            number: ({ children }) => (
              <ol className="ml-[1.6em] list-decimal">{children}</ol>
            ),
          },
          listItem: {
            bullet: ({ children }) => (
              <li
                className={cn(
                  'leading-160 font-light text-grayscale-06',
                  normalTextSizeClassName,
                )}
              >
                {children}
              </li>
            ),
            number: ({ children }) => (
              <li
                className={cn(
                  'leading-160 font-light text-grayscale-06',
                  normalTextSizeClassName,
                )}
              >
                {children}
              </li>
            ),
          },
          marks: {
            internalLink: ({ value, children }) => {
              return (
                <Link
                  className="hyperlink"
                  href={value.href}
                  target={value.target}
                >
                  {children}
                </Link>
              );
            },
            externalLink: ({ value, children }) => {
              return (
                <Link
                  className="hyperlink"
                  href={value.href}
                  target={value.target}
                >
                  {children}
                </Link>
              );
            },
            anchorId: ({ value, children }) => {
              return <span id={value.id}>{children}</span>;
            },
          },
          types: {
            table: ({ value }) => (
              <div className="my-40 md:my-60">
                <ContentTable {...value} />
              </div>
            ),
            image: ({
              value,
            }: PortableTextTypeComponentProps<
              SanityMedia | SanityVideoLoop
            >) => (
              <Media
                media={value}
                className="my-50 m:my-60"
                sizes={['100vw', { m: '800px' }]}
              />
            ),
            videoEmbed: ({
              value,
            }: PortableTextTypeComponentProps<SanityVideoEmbed>) => (
              <VideoEmbed {...value} className="my-50 m:my-60" />
            ),
            quote: ({ value }) => (
              <div className="p-main my:my-75 relative left-1/2 my-50 w-screen -translate-x-1/2 l:my-100">
                <div className="mx-auto max-w-980 m:text-center">
                  <BasicText
                    value={value.quote}
                    className="text-26 font-[440] leading-136 -tracking-1 [hanging-punctuation:first] m:text-34"
                  />
                  {value.attributionText ? (
                    value.attributionUrl ? (
                      <Link
                        href={value.attributionUrl}
                        target="_blank"
                        className="mt-15 inline-block text-16 font-light leading-160 text-grayscale-06 m:mt-30 m:text-20"
                      >
                        {value.attributionText}
                      </Link>
                    ) : (
                      <p className="mt-15 inline-block text-16 font-light leading-160 text-grayscale-06 m:mt-30 m:text-20">
                        {value.attributionText}
                      </p>
                    )
                  ) : null}
                </div>
              </div>
            ),
            divider: () => (
              <hr className="my-60 border-t-1 border-grayscale-03" />
            ),
            code: ({ value }) => (
              <CodeBlock
                className="!my-40"
                code={value.code}
                language={value.language}
              />
            ),
            callout: ({ value }) => (
              <div
                className="my-50 flex gap-x-16 rounded-15 p-13 m:my-60 m:gap-x-50 m:rounded-25 m:p-30 flex-col md:flex-row gap-y-12"
                style={{ background: value.background }}
              >
                <Media
                  media={value.icon}
                  className="size-90 m:size-90"
                  mediaClassName="object-contain"
                  sizes={['90px']}
                />
                <div className="max-w-480 space-y-10 m:space-y-20">
                  <p className="text-14 font-[440] leading-136 -tracking-1 m:text-22 m:leading-120 m:tracking-0">
                    {value.text}
                  </p>
                  <Button href={value.link.href} variant="link">
                    {value.link.title}
                  </Button>
                </div>
              </div>
            ),
          },
        }}
      />
    </div>
  );
}
