import React from 'react';

type TableRow = {
  cells: Array<string>;
  _key: string;
};

type Props = {
  _type: 'table';
  tr: boolean;
  table: {
    rows: Array<TableRow>;
  };
  title?: string;
};

export function ContentTable({ title, table, tr }: Props) {
  const rows = tr ? table.rows.slice(1) : table.rows;
  const headers = tr ? table.rows[0].cells : [];

  return (
    <>
      {title && (
        <h3 className="text-left pl-10 text-26 md:text-32 font-[440] mb-20">
          {title}
        </h3>
      )}
      <div className="overflow-x-auto">
        <table className="table-auto p-10 min-w-full text-14 m:text-16">
          {headers.length > 0 && (
            <thead className="text-left">
              <tr className="border-b font-[440]">
                {headers.map((header, index) => (
                  <th
                    className="font-[440] py-10 px-12 whitespace-nowrap"
                    key={index}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {rows.map((row) => (
              <tr key={row._key} className="border-b">
                {row.cells.map((cell, cellIndex) => (
                  <td
                    className="py-10 px-12 whitespace-nowrap"
                    key={row._key + cellIndex}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
